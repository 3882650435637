<template>
    <div class="card-container">
        <common-header>我的優惠券</common-header>
        <div v-if="cardList.length !== 0 && show" class="container">
            <ul class="card-list">
                <li @click="toDetail(card.id)" v-for="card in yhjCardList" :key="card.title" class="card-item card-item1">
                    <span class="left">
                        <span class="left-title">{{card.cardName}}</span>
                        <span class="left-desc">{{card.titleDesc}}</span>
                    </span>
                    <div class="right">
                        <span class="right-title">{{card.title}}</span>
                        <span class="right-desc">{{card.desc1}}</span>
                        <span class="right-desc">{{card.desc2}}</span>
                    </div>
                </li>
            </ul>
        </div>  
        <no-data v-else-if="cardList.length == 0 && show"></no-data>
    </div>
</template>
<script>
import NoData from '@/components/NoData/noData'
import CommonHeader from '@/components/header'
import {yhjCardList} from '@/constant/constant'
export default {
    data() {
        return {
            cardList: [],
            show: false,
            yhjCardList: []
        }
    },
    methods: {
        toDetail(id) {
            this.$router.push(`/mineCard/detail/${id}`)
        },
        // toggleContent() {
        //     this.isShow = !this.isShow;
        // }
    },
    components: {
        CommonHeader,
        NoData
    },
    mounted() {
        this.yhjCardList = yhjCardList
        // 初始优惠券列表请求数据还没有回来的时候
        // 应该设置一个loading
        this.$store.dispatch('getUserCardList').then(res => {
            console.log('获取优惠券列表,', res)
            this.show = true;
            this.cardList = res.data
        })
    }
}
</script>
<style lang="scss" scoped>
.card-container {
    height: 100vh;
    background: #f2f2f2;
}
.container {
    padding: 20px 16px;
    padding-top: 64px;
}
.card-item {
    background: url('../../assets/images/bg-yhj.png') center center no-repeat;
    background-size: 100%;
}
</style>