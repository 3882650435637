<template>
    <div class="no-data">
        <img class="no-data-img" src="./img/no-data.png" alt="">
        <div class="text">暂无数据~</div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    }
}
</script>
<style lang="scss" scoped>
.no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 200px;
    &-img {
        width: 200px;
        height: 200px;
        border-radius: 80px;
    }

    .text {
        font-size: 16px;
        color: #999;
        margin-top: 20px;
    }
}
</style>